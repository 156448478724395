import React from "react";
import { connect } from "react-redux";
import language from "../../../language/index.js";
import { Table } from "../../Table/index.js";
import { getStorage } from "../../../utils/storage.js";
import { fetchShippingInQueueData } from "../../../utils/queries/request/shipping-queue.js";
import { Empty } from "../../Empty/index.js";
import toastMessage from "../../../utils/toastMessage.js";
import moment from "moment";
import Loading from "../../Loader/Loading.js";
import PrintComponent from "../../Print/PrintComponent.js";
import axios from "axios";
import CardHeader from "../../Header/CardHeader.js";
import rbcLogo from "../../../assets/rbc-logo.png";
import "./styles.css";

class ShippingQueue extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 100000,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    shipmentNumber: "",
    addedBy: "",
    summary: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });

    await this.getData(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  getData = async (isLoading) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, shipmentNumber, addedBy } = this.props;

      if (!shipmentNumber || shipmentNumber === "") return;

      this.setState({ isLoading });

      const requestBody = {
        page,
        limit,
        addedBy,
        shipmentNumber,
      };

      const { data, count } = await fetchShippingInQueueData(
        defaultLanguage,
        requestBody
      );

      const results = this.formatDataForDisplay(this.formatShipmentData(data));

      if (results.length > 0) {
        this.setState({
          data: results,
          totalPageCount: count,
          isLoading: false,
          shipmentNumber: data[0].shipmentNumber,
          product: data[0].product,
          to: `${data[0].to?.name}(${data[0].to?.code})`,
          from: `${data[0].from?.name}(${data[0].from?.code})`,
          date: moment(data[0].date).format("ll"),
          addedBy: data[0].addedBy,
          id: data[0]._id,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });

      toastMessage("error", error);
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });

    if (selectedData?.donation) {
      this.setState({ donation: selectedData.donation });
    }
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: "No",
        key: "number",
      },
      {
        title: "Donation No",
        key: "donationNumber",
      },
      {
        title: "Blood Group",
        key: "bloodGroup",
      },
      {
        title: "Qty",
        key: "quantity",
      },
      {
        title: "Expires on",
        key: "expiredDate",
      },
      {
        title: "Volume",
        key: "totalVolume",
      },
    ];

    return headers;
  }

  handlePrint = async () => {
    if (!this.state.id)
      return toastMessage(language[this.props.defaultLanguage].no_data);
    try {
      this.setState({
        isPrinting: true,
      });
      const API_URL = process.env.REACT_APP_BBMS_BASE_API;
      const options = {
        method: "PUT",
        url: `${API_URL}/shipping-queue`,
        params: {
          id: this.state.id,
          status: "transferred",
          shipmentNumber: this.state.shipmentNumber.toUpperCase(),
        },
        headers: {
          Authorization: `Bearer ${this.state.user.token}`,
        },
      };

      await axios(options);

      this.setState({
        isPrinting: false,
      });

      this.props.handleCloseModal();
      this.props.generateShipmentNumber();
      this.props.onReset();
    } catch (error) {
      this.setState({
        isPrinting: false,
      });
    }
  };

  formatShipmentData(data) {
    const result = {};

    for (const { inventory, donation } of data) {
      const {
        name: product,
        bloodGroup,
        expiredDate,
        volume,
        code,
      } = inventory;
      const { donationNumber } = donation;

      // Initialize nested structure if missing using nullish coalescing (??)
      const donationEntry = (result[product + "(" + code + ")"] ??= {});

      const bloodEntry = (donationEntry[donationNumber] ??= {});

      const groupEntry = (bloodEntry[bloodGroup] ??= {
        totalVolume: 0,
        expiredDate,
        quantity: 0,
      });

      // Accumulate volume
      groupEntry.totalVolume += volume;
      groupEntry.quantity += 1;
    }

    console.log(result);

    return result;
  }

  formatDataForDisplay(summary) {
    const result = [];

    for (const product in summary) {
      const donations = [];
      let donationCount = 1;

      for (const donationNumber in summary[product]) {
        for (const bloodGroup in summary[product][donationNumber]) {
          const { totalVolume, expiredDate, quantity } =
            summary[product][donationNumber][bloodGroup];

          donations.push({
            number: donationCount,
            donationNumber,
            bloodGroup,
            totalVolume,
            expiredDate,
            quantity,
          });

          donationCount++;
        }
      }

      result.push({ product, donations });
    }

    return result;
  }

  reformatData(data) {
    const summary = {};

    // Aggregate data by product and blood group
    data.forEach(({ product, donations }) => {
      // Initialize product summary if it doesn't exist
      if (!summary[product]) {
        summary[product] = {};
      }

      donations.forEach(({ bloodGroup, totalVolume, quantity }) => {
        // Initialize blood group summary if it doesn't exist
        if (!summary[product][bloodGroup]) {
          summary[product][bloodGroup] = {
            bloodGroup,
            totalVolume: 0,
            totalQuantity: 0,
          };
        }

        // Update total volume and quantity for the blood group
        summary[product][bloodGroup].totalVolume += totalVolume;
        summary[product][bloodGroup].totalQuantity += quantity;
      });
    });

    // Convert the summary object into the desired array format
    const result = Object.entries(summary).flatMap(([product, groups]) =>
      Object.values(groups).map(
        ({ bloodGroup, totalVolume, totalQuantity }) => ({
          product,
          bloodGroup,
          totalVolume,
          totalQuantity,
        })
      )
    );

    console.log(result);
    return result;
  }

  render() {
    if (this.state.isLoading)
      return (
        <center>
          <Loading />
        </center>
      );
    if (this.state.data.length === 0)
      return <Empty title={language[this.props.defaultLanguage].no_data} />;
    return (
      <div style={{ marginTop: "1rem" }}>
        <PrintComponent
          handlePrint={this.handlePrint.bind(this)}
          isPrinting={this.state.isPrinting}
        >
          <hr />
          <div className="card-header">
            <div className="shipping-slip-header">
              <div className="left">
                <div className="shipping-slip-logo">
                  <img src={rbcLogo} />
                </div>
                <div style={{ flex: 1 }}>
                  <p className="mb-2">
                    Shipment NO:{" "}
                    <span className="text-bold">
                      {this.state.shipmentNumber}
                    </span>
                  </p>
                </div>
              </div>
              <div className="right">
                <div>
                  <p className="shipping-slip-name">BTD Rwanda, kigali</p>
                  <br />
                  <p className="mb-2">
                    Date: <span className="text-bold">{this.state.date}</span>
                  </p>
                </div>
                <div>
                  <p className="mb-2">
                    Source:{" "}
                    <span
                      className="text-bold"
                      style={{ textTransform: "uppercase" }}
                    >
                      {this.state.from}
                    </span>
                  </p>
                  <p className="mb-2">
                    Destination:{" "}
                    <span
                      className="text-bold"
                      style={{ textTransform: "uppercase" }}
                    >
                      {this.state.to}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card-body"
            style={{ border: 0, marginBottom: "1rem" }}
          >
            {this.state.data.map((el, i) => {
              return (
                <div key={i}>
                  <CardHeader title={el.product} />
                  <Table
                    data={el.donations}
                    searching={this.state.searching}
                    isLoading={this.state.isLoading}
                    handlePagination={this.handlePagination.bind(this)}
                    headers={this.returnTableHeaders()}
                  />
                </div>
              );
            })}
          </div>
          <div
            className="card-body"
            style={{ border: 0, marginBottom: "1rem" }}
          >
            <CardHeader title="Summary" />
            <table className="table table-condensed">
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Blood Group</th>
                  <th>Qty</th>
                  <th>Volume</th>
                </tr>
              </thead>
              <tbody>
                {this.reformatData(this.state.data)?.map((el, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-bold">{el.product}</td>
                      <td className="text-bold">{el.bloodGroup}</td>
                      <td className="text-bold">{el.totalQuantity}</td>
                      <td className="text-bold">{el.totalVolume}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="card-footer" style={{ display: "flex" }}>
            <div>
              <p className="text-bold mb-2">Shipped By</p>
              <p>Name: {this.state.addedBy?.name}</p>
              <p>Date: {moment().format("DD-MM-YYYY HH:mm")}</p>
              <div style={{ margin: "1rem 0" }}>
                <p>Signature: ................................</p>
              </div>
            </div>
            <div style={{ flex: 0.7 }} />
            <div>
              <p className="text-bold mb-2">Recipient</p>
              <p>Names: ..............................................</p>
              <div>
                <p>
                  Date & Time: <span style={{ width: 20 }}>.. /</span>
                  <span style={{ margin: "0 .5rem" }}>.. /</span>
                  <span style={{ margin: "0 .5rem" }}>....</span>
                </p>
              </div>
              <div style={{ margin: "1rem 0" }}>
                <p>Signature: ..............................................</p>
              </div>
            </div>
            <div></div>
          </div>
        </PrintComponent>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(ShippingQueue);
