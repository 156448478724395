import toastMessage from "../../toastMessage";
import formatSelectData from "../../formatSelectData";
import { getStorage } from "../../storage";
import axios from "axios";
import language from "../../../language";
import { defineRole } from "../../handleAccessRoles";
import { ROLE_BLOOD_TEST } from "../../../constants/ROLES";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const fetchData = async (
  lng = "english",
  {
    page,
    limit,
    type,
    donation,
    center,
    centerSite,
    bloodGroup,
    donorNumber,
    q,
    startDate,
    endDate,
    donorId,
    status,
    sortField,
    sortOrder,
    startCollectionDate,
    endCollectionDate,
    bloodGroupingTested,
    serologyTested,
    totalCounts,
    bloodGroupingTestCount,
    serologyTestCount,
    tested,
  }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_BLOOD_TEST,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/bloodtest`,
      params: {
        page: page,
        limit: limit,
        donation,
        center,
        centerSite,
        bloodGroup,
        donorNumber,
        q,
        startDate,
        endDate,
        donor: donorId,
        status,
        sortField,
        sortOrder,
        startCollectionDate,
        endCollectionDate,
        bloodGroupingTested,
        serologyTested,
        totalCounts,
        bloodGroupingTestCount,
        serologyTestCount,
        tested,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    console.log(options);

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "description", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export default fetchData;

export const searchBloodTest = async (
  lng = "english",
  { page, limit, type, donorNumber }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_BLOOD_TEST,
      operation: "read",
    });

    if (!canAccess) return;

    const options = {
      method: "GET",
      url: `${API_URL}/bloodtest/search`,
      params: {
        page: page,
        limit: limit,
        donorNumber,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (type === "dropdown") {
      return formatSelectData(queryRes.data, "description", "_id");
    }

    return !page && !limit ? queryRes.data : { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deletebloodtest = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/bloodtest`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_test);
  } catch (error) {
    toastMessage("error", error);
  }
};

export const bloodTestStatistic = async (
  lng = "english",
  {
    page,
    limit,
    center,
    centerSite,
    serologyTested,
    bloodGroupingTested,
    type,
    startCollectionDate,
    endCollectionDate,
  }
) => {
  try {
    const user = await getStorage();

    const canAccess = defineRole({
      roles: user.accessRole,
      menu: ROLE_BLOOD_TEST,
      operation: "read",
    });

    if (!canAccess) return;
    const options = {
      method: "GET",
      url: `${API_URL}/bloodTest/bloodTestStatistics`,
      params: {
        page: page,
        limit: limit,
        center,
        centerSite,
        serologyTested,
        bloodGroupingTested,
        type,
        startCollectionDate,
        endCollectionDate,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let data = queryRes.data;

    return data;
  } catch (error) {
    toastMessage("error", error);
  }
};
