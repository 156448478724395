import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import { getStorage } from "../../../utils/storage";
import exportPDF from "../../../utils/exportPDF";
import { fetchInventoryAnalytics } from "../../../utils/queries/analytics/inventories";
import { CSVLink } from "react-csv";

let copyData = [];

class ReceptionInventoriesAnalytics extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
    suspendReason: "",
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  returnFilters = async () => {
    const { page, limit } = this.state;
    const { filters } = this.props;
    const { centerRole, centerSiteRole, stock } = await defineRoleCenterSite();
    return {
      page,
      limit,
      center: centerRole,
      centerSite: centerSiteRole,
      available: true,
      stock,
      ...filters,
      startCreatedAt: filters.startDate,
      endCreatedAt: filters.endDate,
    };
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const filters = await this.returnFilters();

      const { data, count } = await fetchInventoryAnalytics(defaultLanguage, {
        ...filters,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "_id.bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "_id.status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].total_packages,
        key: "total",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { defaultLanguage } = this.props;

      const filters = await this.returnFilters();

      const { data } = await fetchInventoryAnalytics(defaultLanguage, filters);

      const response = data.map((el) => {
        return {
          bloodGroup: el._id.bloodGroup,
          status: el._id.status,
          total: el.total,
        };
      });

      this.setState({
        isLoading: false,
        csvData: response,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  downloadPDF = async () => {
    this.setState({
      isLoading: true,
    });

    const { defaultLanguage } = this.props;

    let headers = [
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "_id.bloodGroup",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "_id.status",
      },
      {
        title: language[this.props.defaultLanguage].total_packages,
        key: "total",
      },
    ];

    const filters = await this.returnFilters();

    const { data } = await fetchInventoryAnalytics(defaultLanguage, filters);

    this.setState({
      isLoading: false,
    });

    exportPDF("Reception", headers, data);
  };

  handleSearch(value) {
    this.setState({ search_text: value });

    let data = [];

    for (let el of copyData) {
      if (
        JSON.stringify(el?._id?.bloodGroup)
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
      ) {
        data.push(el);
      }
    }

    this.setState({ data });
  }

  render() {
    console.log(copyData);
    return (
      <>
        <Table
          data={this.state.data}
          page={this.state.page}
          limit={this.state.limit}
          isSearch
          search_text={this.state.search_text}
          placeholder="Search blood group"
          handleSearch={(e) => this.handleSearch(e.target.value)}
          searching={this.state.searching}
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              onPress: () => this.getData(true),
              icon: "refresh",
            },
            {
              type: "dropdown",
              filterName: "date_range",
              title: language[this.props.defaultLanguage].date_range,
              button_type: "dropdown",
              handleApplyFilters: () => this.getData(true),
            },
            {
              type: "export",
              title: language[this.props.defaultLanguage].export_,
              button_type: "dropdown",
              icon: "bx bxs-download",
              options: [
                {
                  name: "PDF",
                  onPress: this.downloadPDF.bind(this),
                },
                {
                  name: "CSV",
                  onPress: this.downloadExcel.bind(this),
                },
              ],
            },
          ]}
        />
        <CSVLink
          ref="csvDownload"
          filename={"Inventories" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(ReceptionInventoriesAnalytics);
