import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import handleSearch from "../../utils/handleSearch";
import handleFilters from "../../utils/handleFilters";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";
import {
  fetchDonors,
  fetchDonorsCounts,
} from "../../utils/queries/report/donors";
import { CardCount } from "../CardCount";
import icons from "../../constants/icons";
import Separator from "../Separator/Separator";
import { CardHeader } from "../Header";
import { DateFilter, DonorCategoryFilter } from "../Filters";

let copyData = [];

class Donors extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selected_data: {},
    totalPageCount: 10,
    delete_password: "",
    error: {},
    csvData: [],
    counts: {
      totalNewDonors: 0,
      totalRegularDonors: 0,
      totalIrregularDonors: 0,
      totalDroppedDonors: 0,
    },
  };

  componentDidMount = async () => {
    await this.fetData(true);
  };

  fetData = async () => {
    await this.getData(true);
    await this.getCounts(true);
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({ error, [field]: e?.target?.value });
  }

  returnFilters = async () => {
    const { page, limit } = this.state;
    const { filters } = this.props;
    const { centerRole, centerSiteRole } = await defineRoleCenterSite();

    return {
      page,
      limit,
      center: centerRole,
      centerSite: centerSiteRole,
      donorCategory: filters.donorCategory,
      ...handleFilters(filters),
    };
  };

  getData = async (isLoading) => {
    try {
      const { defaultLanguage } = this.props;

      this.setState({ isLoading });

      const { data, count } = await fetchDonors(
        defaultLanguage,
        await this.returnFilters()
      );

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });

      console.log(count);

      copyData = data.slice(0);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  getCounts = async (isLoadingCounts) => {
    try {
      const { defaultLanguage, filters } = this.props;

      this.setState({ isLoadingCounts });

      let dataFilters = await this.returnFilters();

      const data = await fetchDonorsCounts(defaultLanguage, dataFilters);

      this.setState({
        counts: data,
        isLoadingCounts: false,
      });
    } catch (error) {
      this.setState({
        isLoadingCounts: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].donor_number,
        key: "donorNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donors/${item._id}/${item.firstName} ${item.lastName}`),
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].first_name,
        key: "firstName",
      },
      {
        title: language[this.props.defaultLanguage].last_name,
        key: "lastName",
      },
      {
        title: language[this.props.defaultLanguage].nationality,
        key: "nationality",
      },
      {
        title: language[this.props.defaultLanguage].identity_doc_type,
        key: "identityDocNumberType",
      },
      {
        title: language[this.props.defaultLanguage].identity_doc_number,
        key: "identityDocNumber",
      },
      {
        title: language[this.props.defaultLanguage].email_address,
        key: "email",
        styles: {
          textTransform: "lowercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {};

  downloadPDF = async () => {};

  render() {
    const { export_ } = language[this.props.defaultLanguage];
    console.log(this.state.counts);
    return (
      <>
        <div style={{ height: 20 }} />
        <div className="d-flex gap-3">
          <DonorCategoryFilter handleApplyFilters={() => this.getData(true)} />
          {/* <DateFilter handleApplyFilters={() => this.getData(true)} /> */}
        </div>
        <div style={{ height: 20 }} />
        <div className="row">
          <div className="col-md-3">
            <CardCount
              count={this.state.counts.totalNewDonors}
              title={language[this.props.defaultLanguage].new_donors}
              color="#078ECE"
              icon={icons.user}
              isLoading={this.state.isLoadingCounts}
            />
          </div>
          <div className="col-md-3">
            <CardCount
              title={language[this.props.defaultLanguage].regular_donors}
              count={this.state.counts.totalRegularDonors}
              isLoading={this.state.isLoadingCounts}
              color="#FF6D00"
              icon={icons.regular}
            />
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.counts.totalIrregularDonors}
              title={language[this.props.defaultLanguage].irregular_donors}
              color="#008000"
              icon={icons.irregular}
              isLoading={this.state.isLoadingCounts}
            />
          </div>
          <div className="col-md-3">
            <CardCount
              count={this.state.counts.totalDroppedDonors}
              title={language[this.props.defaultLanguage].dropped_donors}
              color="#D10000"
              icon={icons.dropped}
              isLoading={this.state.isLoadingCounts}
            />
          </div>
        </div>
        <div style={{ height: 30 }} />
        <div>
          <h3 style={{ textTransform: "uppercase" }}>{`${
            this.props.filters.donorCategory
          } ${language[this.props.defaultLanguage].donors}`}</h3>
        </div>
        <div style={{ height: 10 }} />
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={(e) =>
            handleSearch(this, {
              copyData,
              value: e?.target?.value,
            })
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
        />

        <CSVLink
          ref="csvDownload"
          filename={"Campaigns" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(Donors);
