import React from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../components/Tabs";
import language from "../../../language";
import { withRouter } from "../../../utils/withRouter";
import { getStorage } from "../../../utils/storage";
// import Requests from "../../../components/Requests/Requests";
import { DashboardHeader } from "../../../components/Header";
import accessMenu from "../../../utils/accessMenu";
// import SearchRequest from "../../../components/Requests/SearchRequest";
import DirectTransfer from "../../../components/Requests/shipping";
import Reception from "../../../components/Requests/shipping/reception";
import SearchShipmentQueue from "../../../components/Requests/shipping/Search";
import ShipmentReport from "../../../components/Requests/shipping/report";

class RequestManagementScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };
  render() {
    let tabOptions = [];
    let { canReadBloodRequestAccess, canCreateBloodRequestAccess } =
      accessMenu();

    if (canReadBloodRequestAccess) {
      tabOptions.push(
        {
          title: language[this.props.defaultLanguage].direct_transfer,
          data: <DirectTransfer />,
        },
        {
          title: language[this.props.defaultLanguage].search,
          data: <SearchShipmentQueue />,
        },
        {
          title: language[this.props.defaultLanguage].reception,
          data: <Reception />,
        }
        // {
        //   title: language[this.props.defaultLanguage].report,
        //   data: <ShipmentReport />,
        // }
        // {
        //   title: language[this.props.defaultLanguage].historical_data,
        //   data: <HistoricalData />,
        // }
        // {
        //   title: language[this.props.defaultLanguage].search,
        //   data: (
        //     <div className="card">
        //       <div className="card-body">
        //         <div className="col-md-6">
        //           <SearchRequest />
        //         </div>
        //       </div>
        //     </div>
        //   ),
        // },
        // {
        //   title: language[this.props.defaultLanguage].blood_requests,
        //   data: (
        //     <Requests
        //       showAdd
        //       status={[
        //         "pending",
        //         "processing",
        //         "delivering",
        //         "dispatched",
        //         "pending-pickup",
        //         "pickup",
        //       ]}
        //     />
        //   ),
        // },
        // {
        //   title: language[this.props.defaultLanguage].pending,
        //   data: (
        //     <Requests status="pending" showAdd={canCreateBloodRequestAccess} />
        //   ),
        // },
        // {
        //   title: language[this.props.defaultLanguage].processing,
        //   data: <Requests status={["processing", "pickup"]} />,
        // },
        // {
        //   title: language[this.props.defaultLanguage].dispatch,
        //   data: <Requests status="dispatched" />,
        // },
        // {
        //   title: language[this.props.defaultLanguage].delivered,
        //   data: <Requests status="delivered" />,
        // }
      );
    }

    return (
      <div>
        <div className="card-dashboard-content">
          <div className="card-body">
            <DashboardHeader
              title={language[this.props.defaultLanguage].requests_and_dispatch}
            />
            <Tabs options={tabOptions} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(withRouter(RequestManagementScreen));
