import React from "react";
import { connect } from "react-redux";
import language from "../../language";
import { Table } from "../Table";
import { CSVLink } from "react-csv";
import donorsQuery, { deleteDonors } from "../../utils/queries/donorsQuery";
import NewDonor from "./NewDonor";
import { DeleteModal, Modal } from "../Modal";
import { getStorage } from "../../utils/storage";
import icons from "../../constants/icons";
import { Tabs } from "../Tabs";
import PreAssessment from "./Pre-assessment/Answers/Index";
import BloodCollection from "../BloodCollection/BloodCollection";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../utils/handleAccessRoles";
import Block from "./Block";
import UnBlock from "./UnBlock";
import { ROLE_DONORS } from "../../constants/ROLES";
import DonorFilters from "../Filters/donor/DonorFilters";
import CountDonors from "./Counts";
import { CentersFilter, CenterSitesFilter, DateFilter } from "../Filters";
import handleFilters from "../../utils/handleFilters";

class Donors extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });

    await this.getData(true);
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.filters !== this.props.filters) {
      await this.getData(true);
    }
  };

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getData = async (search = {}) => {
    try {
      const { page, limit, user } = this.state;
      let { defaultLanguage, centerId, siteId, filters } = this.props;

      this.setState({ isLoading: true });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();


      const { data, count } = await donorsQuery(defaultLanguage, {
        page,
        limit,
        center: centerId || centerRole,
        centerSite: siteId || centerSiteRole,
        ...filters,
        ...search,
        ...handleFilters(filters),
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
    });
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].photo,
        key: "imageUrl",
        type: "photo",
      },
      {
        title: language[this.props.defaultLanguage].donor_number,
        key: "donorNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donors/${item._id}/${item.firstName} ${item.lastName}`),
        styles: {
          textTransform: "uppercase",
        },
        copyEnabled: true,
      },
      {
        title: language[this.props.defaultLanguage].category,
        key: "category",
      },
      {
        title: language[this.props.defaultLanguage].first_name,
        key: "firstName",
      },
      {
        title: language[this.props.defaultLanguage].last_name,
        key: "lastName",
      },
      {
        title: language[this.props.defaultLanguage].nationality,
        key: "nationality",
      },
      {
        title: language[this.props.defaultLanguage].identity_doc_type,
        key: "identityDocNumberType",
      },
      {
        title: language[this.props.defaultLanguage].identity_doc_number,
        key: "identityDocNumber",
      },
      {
        title: language[this.props.defaultLanguage].blood_group,
        key: "bloodGroup",
        styles: {
          textTransform: "uppercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].email_address,
        key: "email",
        styles: {
          textTransform: "lowercase",
        },
      },
      {
        title: language[this.props.defaultLanguage].phone_number,
        key: "phoneNumber",
      },
      {
        title: language[this.props.defaultLanguage].center,
        key: "center.name",
      },
      {
        title: language[this.props.defaultLanguage].center_site,
        key: "centerSite.name",
      },
      {
        title: language[this.props.defaultLanguage].place_of_birth,
        key: "placeOfBirth",
      },
      {
        title: language[this.props.defaultLanguage].created_at,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  downloadExcel = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      const { data } = await donorsQuery(defaultLanguage, {
        page,
        limit,
      });

      this.setState({
        isLoading: false,
        csvData: data,
      });

      this.refs.csvDownload?.link.click();
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteDonors(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearchButton = async () => {
    const { search_text } = this.state;

    try {
      const { page, limit } = this.state;
      const { defaultLanguage } = this.props;

      this.setState({ isLoading: true });

      const { data, count } = await donorsQuery(defaultLanguage, {
        page,
        limit,
        donorNumber: search_text,
      });

      this.setState({
        data: data,
        totalPageCount: count,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    await this.getData({
      q: search || search !== "" ? search : undefined,
    });

    this.setState({
      searching: false,
    });
  };

  render() {
    const { add_donor, donor, delete_, edit, view } =
      language[this.props.defaultLanguage];

    return (
      <>
        <div style={{ marginBottom: 16 }}>
          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
            <CentersFilter />
            <CenterSitesFilter />
            <DateFilter />
          </div>
        </div>
        <CountDonors filters={this.props.filters} />
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          addSearchButton
          searching={this.state.searching}
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={defineRole({
            roles: this.state.user.accessRole,
            menu: ROLE_DONORS,
            operation: "create",
          })}
          addButtonText={add_donor}
          handleAddPressed={this.handleShowModal.bind(this, "showModal", donor)}
          rowPress={(item) =>
            this.handleShowModal("showModal", item.name, item)
          }
          actions={[
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_DONORS,
                operation: "read",
              }),
              name: view,
              onPress: (item) =>
                (window.location.href = `/dashboard/donors/${item._id}/${item.firstName} ${item.lastName}`),

              icon: icons.eye_on,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_DONORS,
                operation: "update",
              }),
              name: edit,
              onPress: (item) =>
                this.handleShowModal("showModal", item.donorNumber, item),
              icon: icons.edit,
            },
            // this.props.blockActive &&
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_DONORS,
                operation: "block",
              }),
              name: language[this.props.defaultLanguage].block,
              onPress: (item) =>
                this.handleShowModal(
                  "showBlockModal",
                  language[this.props.defaultLanguage].block +
                    " " +
                    item.donorNumber,
                  item
                ),
              icon: icons.block,
            },
            // this.props.unblockActive &&
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_DONORS,
                operation: "unblock",
              }),
              name: language[this.props.defaultLanguage].unblock,
              onPress: (item) =>
                this.handleShowModal(
                  "showUnblockModal",
                  language[this.props.defaultLanguage].unblock +
                    " " +
                    item.donorNumber,
                  item
                ),
              icon: icons.success,
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_DONORS,
                operation: "delete",
              }),
              name: delete_,
              onPress: (item) =>
                this.handleShowModal(
                  "showDeleteModal",
                  language[this.props.defaultLanguage].delete,
                  item
                ),
              icon: icons.delete,
            },
          ]}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(),
            },
            {
              type: "button",
              title: language[this.props.defaultLanguage].filters,
              button_type: "button",
              icon: icons.filters,
              onPress: (item) =>
                this.handleShowModal(
                  "filtersModal",
                  language[this.props.defaultLanguage].filters
                ),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
          backdrop="static"
        >
          {this.state?.selectedData?._id ? (
            <Tabs
              options={[
                {
                  title: language[this.props.defaultLanguage].donor_information,
                  data: (
                    <NewDonor
                      handleCloseModal={this.handleCloseModal.bind(
                        this,
                        "showModal"
                      )}
                      getData={this.getData.bind(this)}
                      {...this.state.selectedData}
                      route={this.props.route}
                    />
                  ),
                },
                {
                  title:
                    language[this.props.defaultLanguage]
                      .pre_donation_assessment,
                  data: (
                    <div className="card-body">
                      <PreAssessment donorId={this.state.selectedData._id} />
                    </div>
                  ),
                },
                {
                  title: language[this.props.defaultLanguage].blood_donation,
                  data: (
                    <div className="card-body">
                      <BloodCollection donorId={this.state.selectedData._id} />
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <NewDonor
              route="/dashboard"
              handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
              getData={this.getData.bind(this)}
              {...this.state.selectedData}
            />
          )}
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showBlockModal")}
          show={this.state.showBlockModal}
          title={this.state.modalTitle}
        >
          <Block
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showBlockModal"
            )}
            getData={this.getData.bind(this)}
            donor={this.state.selectedData}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showUnblockModal")}
          show={this.state.showUnblockModal}
          title={this.state.modalTitle}
        >
          <UnBlock
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showUnblockModal"
            )}
            getData={this.getData.bind(this)}
            donor={this.state.selectedData}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          <DeleteModal
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showDeleteModal"
            )}
            error={this.state.error.delete_password}
            value={this.state.delete_value}
            onDelete={this.onDelete.bind(this)}
            onChange={(e) => this.onChangeText("delete_value", e)}
            isDeleting={this.state.isDeleting}
            name={this.state.selectedData.email}
            getData={this.getData.bind(this)}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "filtersModal")}
          show={this.state.filtersModal}
          title={this.state.modalTitle}
          size="lg"
        >
          <div className="card-body">
            <div className="row">
              <DonorFilters
                fetchData={(params) => this.getData(params)}
                handleCloseModal={this.handleCloseModal.bind(
                  this,
                  "filtersModal"
                )}
              />
            </div>
          </div>
        </Modal>
        <CSVLink
          ref="csvDownload"
          filename={"Requests" + new Date().getTime()}
          data={this.state.csvData}
        ></CSVLink>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  // const { filters } = state.Filters;
  return {
    defaultLanguage,
    // filters,
  };
};

export default connect(mapStateToProps)(Donors);
