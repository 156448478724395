import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../../Input";
import { Button } from "../../Button";
import language from "../../../language";
import axios from "axios";
import toastMessage from "../../../utils/toastMessage";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import { getStorage } from "../../../utils/storage";
import { fetchEquipments } from "../../../utils/queries/equipmentQuery";
import availableOptions from "../../../constants/availableOptions";
import { fetchTransportationTemperatures } from "../../../utils/queries/transportationTemperatures";
import { findTemperatureRange } from "../../../utils/findTemperatureInRange";
import { fetchBagStatus } from "../../../utils/queries/bagStatus";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

const bagCategories = [
  {
    label: "WHOLE BLOOD",
    value: "WHOLE BLOOD",
  },
  {
    label: "APHERESIS PLATELET",
    value: "APHERESIS PLATELET",
  },
  {
    label: "APHERESIS PLASMA",
    value: "APHERESIS PLASMA",
  },
  {
    label: "RED BLOOD CELLS (RBC)",
    value: "RED BLOOD CELLS (RBC)",
  },
  {
    label: "FRESH FROZEN PLASMA (FFP)",
    value: "FRESH FROZEN PLASMA (FFP)",
  },
  {
    label: "BIO-PLASMA",
    value: "BIO-PLASMA",
  },
  {
    label: "PLATELETS",
    value: "PLATELETS",
  },
];

class NewReception extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      error: {},
      isSubmitting: false,
      volume: "",
      transportationTemperature: "",
      equipments: [],
      donation: [],
      isLoading: true,
      labels: [],
      available: availableOptions[0],
      centers: [],
      centerSites: [],
      category: bagCategories[0],
      donationId: props.donationId,
      code: "",
      temperatures: [],
      inventoryStatuses: [],
      bagStatuses: [],
      donationNumber: "",
    };
  }

  componentDidMount = async () => {
    this.getBagStatus(true);
    this.getTemperatures(true);
    this.getEquipment(true);

    if (this.props._id) {
      await this.setState(
        {
          equipment: this.props.equipment
            ? {
                label: this.props.equipment.name,
                value: this.props.equipment._id,
              }
            : null,
          weight: this.props.weight,

          status: this.props.status
            ? {
                label: this.props.status,
                value: this.props.status,
              }
            : null,
          label: {
            label: this.props.label,
            value: this.props.label,
          },
          available: {
            label: this.props.available + "",
            value: this.props.available + "",
          },
          transportationTemperature: this.props.transportationTemperature,
          category: this.props.category
            ? {
                label: this.props.category,
                value: this.props.category,
              }
            : null,
          donationNumber: this.props.donation.donationNumber,
        },
        () => {
          this.getDonation(true, this.props.donation.donationNumber);
        }
      );
    }
  };

  getTemperatures = async () => {
    try {
      let { status } = this.state;
      this.setState({
        isFetchingTemperatures: true,
      });

      const { data } = await fetchTransportationTemperatures(
        this.props.defaultLanguage,
        { page: 1, limit: 100 }
      );

      const temperatureRange = findTemperatureRange(
        this.state.transportationTemperature,
        data
      );

      let inventoryStatuses = temperatureRange.map((el) => {
        return {
          label: el.decision,
          value: el.decision,
        };
      });

      if (inventoryStatuses && inventoryStatuses.length === 1) {
        // status = inventoryStatuses[0];
      }

      this.setState({
        temperatures: data,
        isFetchingTemperatures: false,
        inventoryStatuses,
        // status,
      });
    } catch (error) {
      toastMessage("error", error);

      this.setState({
        isFetchingTemperatures: false,
      });
    }
  };

  validateDonationSearch() {
    let { error, transportationTemperature, equipment, donationNumber } =
      this.state;

    if (transportationTemperature === "") {
      error.transportationTemperature =
        language[
          this.props.defaultLanguage
        ].transportation_temperature_required;
    }

    if (!equipment) {
      error.equipment = language[this.props.defaultLanguage].equipment_required;
    }

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    this.setState({
      error,
    });
  }

  getDonation = async (searchDonation) => {
    try {
      await this.validateDonationSearch();

      let { error, donationNumber } = this.state;

      if (Object.keys(error).length > 0) return;

      this.setState({
        searchDonation,
      });

      const data = await bloodDonationQuery(this.props.defaultLanguage, {
        donationNumber: donationNumber?.replace(/\s+/g, ""),
      });

      let donation = {};

      if (data.length > 0) {
        donation = data[0];

        this.setState({
          donation,
        });
      } else {
        toastMessage("error", "Donation not found!");
      }

      this.setState({
        searchDonation: false,
      });
    } catch (error) {
      this.setState({ searchDonation: false });
      toastMessage("error", "Donation not found!");
    }
  };

  getEquipment = async (isFetchingEquipment) => {
    try {
      this.setState({
        isFetchingEquipment,
      });

      const data = await fetchEquipments(this.props.defaultLanguage, {
        type: "dropdown",
        department: "production",
      });

      this.setState({
        equipments: data,
        isFetchingEquipment: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingEquipment: false });
    }
  };

  getBagStatus = async (isFetchingBagStatus, value) => {
    try {
      this.setState({
        isFetchingBagStatus,
      });

      const data = await fetchBagStatus(this.props.defaultLanguage);

      this.setState({
        bagStatuses: data,
        isFetchingBagStatus: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingBagStatus: false });
    }
  };

  onChangeText = async (field, e) => {
    let { error, label } = this.state;
    let inputValue = e.target ? e?.target?.value : e;

    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "center") {
      this.setState({ site: null });
      this.getSites(true, inputValue.value);
    }

    if (this.state.weight === "") {
      this.setState({ label: null });

      return;
    }

    if (field === "weight") {
      const comparedNumber = Number(inputValue);

      const wholeBloodWeight = this.checkBagStatus(comparedNumber);
      this.setState({
        label: wholeBloodWeight,
        status:
          {
            label: wholeBloodWeight?.status,
            value: wholeBloodWeight?.status,
          } || this.state.status,
      });
    }

    if (field === "transportationTemperature" && inputValue !== "") {
      this.getTemperatures();
    }
  };

  checkBagStatus(comparedNumber) {
    this.state.bagStatuses.sort((a, b) => {
      if (a.value === "rejected") return -1; // Move 'Rejected' to the front
      if (b.value === "rejected") return 1;
      return 0;
    });

    for (let el of this.state.bagStatuses) {
      if (el.min <= comparedNumber && el.max >= comparedNumber) {
        return el;
      }
    }

    return {
      label: "REJECTED",
      value: "rejected",
      status: "rejected",
    };
  }

  validateForm() {
    let {
      equipment,
      weight,
      error,
      transportationTemperature,
      status,
      label,
      category,
    } = this.state;

    if (transportationTemperature === "") {
      error.transportationTemperature =
        language[
          this.props.defaultLanguage
        ].transportation_temperature_required;
    }

    if (!equipment) {
      error.equipment = language[this.props.defaultLanguage].equipment_required;
    }

    if (!label) {
      error.label = language[this.props.defaultLanguage].label_required;
    }

    if (!weight || weight === "") {
      error.weight = language[this.props.defaultLanguage].weight_required;
    }

    if (!category) {
      error.category = language[this.props.defaultLanguage].category_required;
    }

    if (!status) {
      error.status = language[this.props.defaultLanguage].status_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { _id } = this.props;
      let {
          equipment,
          donation,
          label,
          status,
          category,
          transportationTemperature,
          weight,
        } = this.state,
        url = `${API_URL}/bloodInventory`,
        method = "PUT",
        user = await getStorage();

      let requestedBody = {
        donation: donation._id,
        equipment: equipment.value,
        label: label.label,
        status: label.value === "rejected" ? "rejected" : status.value,
        transportationTemperature,
        weight,
        category: category?.value,
      };

      console.log(requestedBody);

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;

        delete requestedBody.addedBy;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };
      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
            donation: null,
            donationNumber: "",
            donation: {},
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT" ? "update_data_success" : "add_data_success"
            ]
          );

          // window.location.reload();
          this.props.handleCloseModal && this.props.handleCloseModal();
          this.props.getData && this.props.getData();
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          toastMessage("error", error);
        });
    }
  };

  onReset = () => {
    this.setState({
      donation: {},
      error: {},
      donationNumber: "",
    });
  };

  render() {
    return (
      <>
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-md-6">
                <Select
                  options={this.state.equipments}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].used_equipment_id}
                  required
                  value={this.state.equipment}
                  onChange={(e) => this.onChangeText("equipment", e)}
                  error={this.state.error.equipment}
                  isLoading={this.state.isFetchingEquipment}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={
                    language[this.props.defaultLanguage]
                      .transportation_temperature
                  }
                  placeholder={
                    language[this.props.defaultLanguage]
                      .transportation_temperature_placeholder
                  }
                  required
                  value={this.state.transportationTemperature}
                  error={this.state.error.transportationTemperature}
                  onChange={(e) =>
                    this.onChangeText("transportationTemperature", e)
                  }
                  type="number"
                  isLoading={this.state.isFetchingTemperatures}
                  min={-1000}
                />
              </div>
              <div className="col-md-12">
                <Input
                  placeholder={
                    language[this.props.defaultLanguage].search + "..."
                  }
                  label={language[this.props.defaultLanguage].donation_number}
                  required
                  value={this.state.donationNumber}
                  error={this.state.error.donationNumber}
                  onChange={(e) => this.onChangeText("donationNumber", e)}
                />
              </div>
              {!this.state.donation?._id && (
                <div>
                  <Button
                    text={language[this.props.defaultLanguage].search}
                    onPress={(e) => {
                      e.preventDefault();
                      this.getDonation(true);
                    }}
                    isSubmitting={this.state.searchDonation}
                  />
                </div>
              )}
            </div>
          </form>
          <form>
            <div className="row">
              {this.state.donation?._id && (
                <>
                  <div className="col-md-6">
                    <Input
                      label={language[this.props.defaultLanguage].weight}
                      placeholder={
                        language[this.props.defaultLanguage].weight_placeholder
                      }
                      required
                      value={this.state.weight}
                      error={this.state.error.weight}
                      onChange={(e) => this.onChangeText("weight", e)}
                      type="number"
                    />
                  </div>

                  <div className="col-md-6">
                    <Select
                      loadOptions={(e) => this.getBagStatus(true, e)}
                      placeholder={language[this.props.defaultLanguage].select}
                      label={language[this.props.defaultLanguage].weight_status}
                      value={this.state.label}
                      onChange={(e) => this.onChangeText("label", e)}
                      error={this.state.error.label}
                      disabled
                      isLoading={this.state.isFetchingBagStatus}
                    />
                  </div>

                  <div className="col-md-6">
                    <Select
                      options={bagCategories}
                      placeholder={language[this.props.defaultLanguage].select}
                      label={language[this.props.defaultLanguage].category}
                      required
                      value={this.state.category}
                      onChange={(e) => this.onChangeText("category", e)}
                      error={this.state.error.category}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      // options={this.state.inventoryStatuses}
                      options={[
                        {
                          label: "Received",
                          value: "received",
                        },
                        {
                          label: "Rejected",
                          value: "rejected",
                        },
                      ]}
                      label={language[this.props.defaultLanguage].status}
                      value={this.state.status}
                      error={this.state.error.status}
                      isLoading={this.state.isFetchingTemperatures}
                      onChange={(e) => this.onChangeText("status", e)}
                    />
                  </div>
                </>
              )}

              {this.state.donation?._id && (
                <div className="d-flex gap-2">
                  <Button
                    text={language[this.props.defaultLanguage].reset}
                    onPress={this.onReset.bind(this)}
                    className="btn-default"
                    type="button"
                  />
                  <Button
                    text={language[this.props.defaultLanguage].submit}
                    onPress={(e) => {
                      e.preventDefault();
                      this.onSubmit();
                    }}
                    isSubmitting={this.state.isSubmitting}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewReception);
