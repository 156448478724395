import React from "react";
import { connect } from "react-redux";
import { defineRoleCenterSite } from "../../utils/handleAccessRoles";
import handleFilters from "../../utils/handleFilters";
import { fetchDonationSiteDistrictData } from "../../utils/queries/analytics/bloodCollectionSiteDistrict";
import { Loading } from "../Loader";
import { Bar } from "react-chartjs-2";
import moment from "moment";

const options = {
  maintainAspectRatio: false,
  animation: false,
  offset: true,
};

class BloodCollectionSites extends React.Component {
  state = {
    data: [],
  };

  componentDidMount() {
    this.getData(true);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.getData(true);
    }
  }

  getData = async (isLoading) => {
    try {
      const { defaultLanguage, filters } = this.props;

      this.setState({ isLoading });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const { data } = await fetchDonationSiteDistrictData(defaultLanguage, {
        center: centerRole,
        centerSite: centerSiteRole,
        ...handleFilters(filters),
      });

      const formateData = this.handleDataFormation(data);

      console.log("formateData", data);

      this.setState({
        data: formateData,
        isLoading: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoading: false,
      });
    }
  };

  handleDataFormation(data) {
    const chartData = {},
      labels = [];
    data.forEach((item) => {
      const separator = item?.district;

      labels.push(separator || "unknown");

      if (!chartData[separator]) {
        chartData[separator] = {
          RURAL: 0,
          SCHOOLS: 0,
          sites: 0,
        };
      }

      chartData[separator].sites += item.sites;
      chartData[separator]["RURAL"] += item.ruralSites;
      chartData[separator]["SCHOOLS"] += item.schoolSites;
    });

    let ruralSites = [],
      schoolSites = [],
      total = [];

    Object.keys(chartData).forEach((key) => {
      ruralSites.push(chartData[key]["RURAL"]);
      schoolSites.push(chartData[key]["SCHOOLS"]);
      total.push(chartData[key].sites);
    });

    console.log(ruralSites);
    console.log(schoolSites);

    return {
      labels: labels,
      datasets: [
        {
          label: "RURAL",
          data: ruralSites,
          backgroundColor: "#AC0207",
          borderColor: "#AC0207",
          radius: 5,
        },
        {
          label: "SCHOOLS",
          data: schoolSites,
          backgroundColor: "#F7B811",
          borderColor: "#F7B811",
          radius: 5,
        },
        {
          label: "Total",
          data: total,
          backgroundColor: "#DDDDDD",
          borderColor: "#DDDDDD",
          radius: 5,
        },
      ],
    };
  }
  render() {
    return (
      <div className="card">
        <div className="card-header">
          <h3>Blood Collection per Sites</h3>
          <small>
            {moment(this.props.filters.startDate).format("DD-MM-YYYY")}
            {" - "}
            {moment(this.props.filters.ENDdATE).format("DD-MM-YYYY")}
          </small>
        </div>

        <div className="card-body">
          {this.state.isLoading ? (
            <div
              style={{
                height: 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loading />
            </div>
          ) : this.state.data.length === 0 ? (
            <div
              style={{
                height: 400,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span className="text-disabled">
                No Data for Blood Collection Sites
              </span>
            </div>
          ) : (
            <>
              <Bar
                width="100%"
                height="400px"
                data={this.state.data}
                options={options}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  const { filters } = state.Filters;
  return {
    defaultLanguage,
    filters,
  };
};

export default connect(mapStateToProps)(BloodCollectionSites);
