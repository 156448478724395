import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import { Table } from "../../Table";
import { DeleteModal, Modal } from "../../Modal";
import {
  defineRole,
  defineRoleCenterSite,
} from "../../../utils/handleAccessRoles";
import { getStorage } from "../../../utils/storage";
import icons from "../../../constants/icons";
import { SearchDonation } from "../../BloodCollection";
import {
  fetchComponentSeparationData,
  deleteBloodSeparation,
} from "../../../utils/queries/production/componentSeparationQuery";
import bloodDonationQuery from "../../../utils/queries/bloodDonationQuery";
import {
  ROLE_COMPONENT_SEPARATION,
  ROLE_LABELING,
} from "../../../constants/ROLES";
import PrintableLabel from "../ComponentLabeling/printableLabel";
import { onGenerateLabel } from "../ComponentLabeling/utils";
import { Loading } from "../../Loader";
import { Empty } from "../../Empty";
import { fetchComponentTransformationData } from "../../../utils/queries/production/componentTransformationQuery";
import UpdateComponent from "./UpdateComponent";
import toastMessage from "../../../utils/toastMessage";

let copyData = [];

class Separations extends React.Component {
  state = {
    isLoading: false,
    data: [],
    page: 1,
    limit: 10,
    search_text: "",
    selectedData: {},
    delete_value: "",
    error: {},
    csvData: [],
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();
    this.setState({ user });

    await this.getData(true);
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isRefreshing !== this.props.isRefreshing ||
      prevProps.componentProfile !== this.props.componentProfile ||
      prevProps.component !== this.props.component
    ) {
      this.getData(true);
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e.target ? e?.target?.value : e,
      error,
    });
  }

  getData = async (isLoading, search = {}) => {
    try {
      const { page, limit } = this.state;
      const { defaultLanguage, queryType, type, filters } = this.props;
      const user = await getStorage();

      this.setState({ isLoading });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const requestBody = {
        page,
        limit,
        donor: this.props.donorId,
        donation: this.props.donationId,
        id: this.props.separationId,
        center: centerRole,
        centerSiteRole: centerSiteRole,
        ...search,
        addedBy: this.props.separationId ? user.id : undefined,
      };

      if (queryType === "bulk") {
        delete requestBody.donation;
        requestBody.componentProfile = this.props.componentProfile;
        requestBody.component = this.props.component;
      }

      if (type === "separation") {
        const { data, count } = await fetchComponentSeparationData(
          defaultLanguage,
          requestBody
        );

        this.setState({
          data: data,
          totalPageCount: count,
          isLoading: false,
        });

        copyData = data.slice(0);
      }

      if (type === "transformation") {
        const { data, count } = await fetchComponentTransformationData(
          defaultLanguage,
          requestBody
        );

        this.setState({
          data: data,
          totalPageCount: count,
          isLoading: false,
        });

        copyData = data.slice(0);
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  handleShowModal(modal, modalTitle, selectedData = {}) {
    this.setState({
      [modal]: true,
      modalTitle: modalTitle,
      selectedData,
      reason: selectedData.reason || "",
    });

    if (selectedData?.donation) {
      this.setState({ donation: selectedData.donation });
    }
  }

  handleCloseModal(modal) {
    this.setState({
      [modal]: false,
    });
  }

  returnTableHeaders() {
    let headers = [
      {
        title: language[this.props.defaultLanguage].code,
        key: "code",
      },
      {
        title: language[this.props.defaultLanguage].name,
        key: "name",
      },
      {
        title: language[this.props.defaultLanguage].donation,
        key: "donation.donationNumber",
        type: "link",
        onPress: (item) =>
          (window.location.href = `/dashboard/donations/${item.donation._id}/${item.donation.donor}`),
      },
      {
        title: language[this.props.defaultLanguage].volume,
        key: "volume",
      },
      {
        title: language[this.props.defaultLanguage].equipment,
        key: "equipment.name",
      },
      {
        title: language[this.props.defaultLanguage].date,
        key: "createdAt",
        isMoment: true,
        formatTime: "lll",
      },
      {
        title: language[this.props.defaultLanguage].status,
        key: "status",
        type: "status",
      },
      {
        title: language[this.props.defaultLanguage].separated_by,
        key: "addedBy.pin",
      },
      {
        title: language[this.props.defaultLanguage].actions,
        key: "action",
      },
    ];

    return headers;
  }

  onDelete = async () => {
    try {
      this.setState({ isDeleting: true });

      await deleteBloodSeparation(
        this.props.defaultLanguage,
        this.state.selectedData._id
      );

      this.setState({ isDeleting: false });

      this.getData(true);
      this.handleCloseModal("showDeleteModal");
    } catch (error) {
      this.setState({
        isDeleting: false,
      });
    }
  };

  handleSearch = async (e) => {
    const search = e?.target?.value;
    this.setState({ search_text: search });
  };

  handleRemoteSearch = async (search) => {
    this.setState({
      searching: true,
    });

    const donation = await bloodDonationQuery(this.props.defaultLanguage, {
      donationNumber: search || search !== "" ? search : undefined,
    });

    if (donation && donation.length === 0) {
      this.setState({
        searching: false,
      });
      return toastMessage(
        "error",
        language[this.props.defaultLanguage].no_data
      );
    }

    await this.getData(true, {
      donation: donation[0]._id,
    });

    this.setState({
      searching: false,
    });
  };

  onOpenGLabel = async (data) => {
    try {
      this.setState({ isGeneratingLabel: true });
      const invData = await onGenerateLabel(this, data, this.props.type);

      this.setState({ isGeneratingLabel: false });

      if (invData) {
        this.setState({ productId: invData._id });
      }

      console.log(invData);

      return;
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { add } = language[this.props.defaultLanguage];
    return (
      <>
        <Table
          data={this.state.data}
          isSearch
          page={this.state.page}
          limit={this.state.limit}
          search_text={this.state.search_text}
          handleSearch={this.handleSearch.bind(this)}
          searching={this.state.searching}
          addSearchButton
          handlePressSearch={() =>
            this.handleRemoteSearch(this.state.search_text)
          }
          totalPageCount={this.state.totalPageCount}
          isLoading={this.state.isLoading}
          handlePagination={this.handlePagination.bind(this)}
          headers={this.returnTableHeaders()}
          showAdd={this.props.showAdd}
          addButtonText={add}
          handleAddPressed={() =>
            this.handleShowModal(
              this.props.donationId ? "showModal" : "showSearchModal",
              this.props.donationId
                ? language[this.props.defaultLanguage].add
                : language[this.props.defaultLanguage].search
            )
          }
          actions={[
            // {
            //   canAccess: true,
            //   // defineRole({
            //   //   roles: this.state.user.accessRole,
            //   //   menu: ROLE_LABELING,
            //   //   operation: "Read",
            //   // }),
            //   name: language[this.props.defaultLanguage].labeling,
            //   icon: icons.labeling,
            //   onPress: (item) => {
            //     this.handleShowModal("showLabelModal", item.code, item);
            //     this.onOpenGLabel(item);
            //   },
            // },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COMPONENT_SEPARATION,
                operation: "update",
              }),
              name: language[this.props.defaultLanguage].edit,
              icon: icons.edit,
              onPress: (item) =>
                this.handleShowModal("showModal", item.code, item),
            },
            {
              canAccess: defineRole({
                roles: this.state.user.accessRole,
                menu: ROLE_COMPONENT_SEPARATION,
                operation: "delete",
              }),
              name: language[this.props.defaultLanguage].delete,
              onPress: this.handleShowModal.bind(
                this,
                "showDeleteModal",
                language[this.props.defaultLanguage].delete
              ),
              icon: icons.delete,
            },
          ]}
          filters={[
            {
              type: "button",
              title: language[this.props.defaultLanguage].refresh,
              button_type: "button",
              icon: icons.refresh,
              onPress: (item) => this.getData(true),
            },
          ]}
        />
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showModal")}
          show={this.state.showModal}
          title={this.state.modalTitle}
          showHeaderBottomBorder={false}
          size="lg"
        >
          <UpdateComponent
            handleCloseModal={this.handleCloseModal.bind(this, "showModal")}
            getData={this.getData.bind(this)}
            {...this.state.selectedData}
            type={this.props.type}
          />
        </Modal>
        <Modal
          handleClose={this.handleCloseModal.bind(this, "showSearchModal")}
          show={this.state.showSearchModal}
          title={this.state.modalTitle}
        >
          <SearchDonation
            handleCloseModal={this.handleCloseModal.bind(
              this,
              "showSearchModal"
            )}
            handleNext={(item) =>
              this.handleShowModal("showModal", item._id, { donation: item })
            }
          />
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showDeleteModal")}
          show={this.state.showDeleteModal}
          title={this.state.modalTitle}
        >
          {this.state.selectedData.status === "pending" ? (
            <DeleteModal
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showDeleteModal"
              )}
              error={this.state.error.delete_password}
              value={this.state.delete_value}
              onDelete={this.onDelete.bind(this)}
              onChange={(e) => this.onChangeText("delete_value", e)}
              isDeleting={this.state.isDeleting}
              name={this.state.selectedData._id}
              getData={this.getData.bind(this)}
            />
          ) : (
            <Empty
              title={`You can't delete data with ${this.state.selectedData.status} status`}
            />
          )}
        </Modal>

        <Modal
          handleClose={this.handleCloseModal.bind(this, "showLabelModal")}
          show={this.state.showLabelModal}
          showHeaderBottomBorder={false}
          title={this.state?.component?.code}
        >
          {this.state.isGeneratingLabel ? (
            <center>
              <Loading />
            </center>
          ) : this.state.selectedData?._id ? (
            <PrintableLabel
              handleCloseModal={this.handleCloseModal.bind(
                this,
                "showLabelModal"
              )}
              productId={this.state.selectedData._id}
            />
          ) : (
            <Empty title={language[this.props.defaultLanguage].no_data} />
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(Separations);
