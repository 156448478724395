import toastMessage from "../../toastMessage";
import { getStorage } from "../../storage";
import axios from "axios";
import language from "../../../language";
import { defineRoleCenterSite } from "../../handleAccessRoles";
import moment from "moment";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

export const fetchInventoryData = async (
  lng = "english",
  {
    page,
    limit,
    donation,
    donor,
    id,
    type,
    center,
    centerSite,
    bloodGroup,
    q,
    isCount,
    status,
    donationNumber,
    sortField,
  }
) => {
  try {
    const user = await getStorage();

    const { centerRole, centerSiteRole } = await defineRoleCenterSite();

    const options = {
      method: "GET",
      url: `${API_URL}/bloodInventory`,
      params: {
        page: page,
        limit: limit,
        donation,
        donor,
        id,
        center: centerRole || center,
        centerSite: centerSiteRole || centerSite,
        bloodGroup,
        q,
        count: isCount,
        status,
        donationNumber,
        sortField,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    if (type === "dropdown") {
      return queryRes.data.map((el) => {
        return {
          ...el,
          label: `${el.category},  ${el.bloodGroup}, ${el.donation._id}, ${el.label}`,
          value: el._id,
        };
      });
    }

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const deleteInventory = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/bloodInventory`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].delete_data_success);
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchInventories = async (
  lng = "english",
  {
    page,
    limit,
    donation,
    id,
    code,
    center,
    centerSite,
    status,
    startDate,
    endDate,
    isCount,
    isLabeled,
    receivedBy,
  }
) => {
  try {
    const user = await getStorage();

    const { centerRole, centerSiteRole, stock } = await defineRoleCenterSite();

    const options = {
      method: "GET",
      url: `${API_URL}/inventory`,
      params: {
        page: page,
        limit: limit,
        donation,
        id,
        center: centerRole || center,
        centerSite: centerSiteRole || centerSite,
        status,
        startCreatedAt: startDate,
        endCreatedAt: endDate
          ? moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm")
          : undefined,
        count: isCount,
        stock,
        isLabeled,
        code,
        receivedBy,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    if (isCount) {
      return queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};

export const fetchInventoryByStatus = async (
  lng = "english",
  {
    page,
    limit,
    donation,
    id,
    type,
    center,
    centerSite,
    status,
    startDate,
    endDate,
    receivedBy,
  }
) => {
  try {
    const user = await getStorage();

    const { centerRole, centerSiteRole } = await defineRoleCenterSite();

    const options = {
      method: "GET",
      url: `${API_URL}/bloodInventory/analytics-status`,
      params: {
        page: page,
        limit: limit,
        donation,
        id,
        center: centerRole || center,
        centerSite: centerSiteRole || centerSite,
        status,
        startDate,
        endDate: moment(endDate).endOf("day").format("YYYY-MM-DD HH:mm"),
        receivedBy,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    if (type === "dropdown") {
      return queryRes.data.map((el) => {
        return {
          ...el,
          label: `${el.category},  ${el.bloodGroup}, ${el.donation._id}, ${el.label}`,
          value: el._id,
        };
      });
    }

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    return { data, count };
  } catch (error) {
    toastMessage("error", error);
  }
};
