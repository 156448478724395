import React from "react";
import { Link } from "react-router-dom";
import { Button, DropdownButton } from "../Button";
import { Input } from "../Input";
import { Pagination } from "../Pagination";
import "./styles.css";
import { Loading } from "../Loader";
import { CSVLink } from "react-csv";
import {
  CentersFilter,
  DateFilter,
  DepartmentFilter,
  HospitalsFilter,
  BloodGroupFilter,
  DonorCategoryFilter,
  CenterSitesFilter,
} from "../Filters";
import TableData from "./TableData";
import InstitutionFilter from "../Filters/InstitutionFilter";
import HospitalFilter from "../Filters/HospitalFilter";

let copyData = [];
class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page ? props.page : 1,
      limit: props.limit,
      search_text: "",
      data: props.data,
    };
  }

  componentDidMount(props) {
    copyData = this.props.data;
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.data !== this.props.data) {
      this.setState({
        data: this.props.data,
      });
    }
  }

  handleSearch(e) {
    let new_data = [],
      search_text = e?.target?.value;

    for (let i = 0; i < copyData.length; i++) {
      if (
        JSON.stringify(copyData[i])
          .toUpperCase()
          .indexOf(search_text.toUpperCase()) !== -1
      ) {
        new_data.push(copyData[i]);
      }
    }

    this.setState({
      data: new_data,
      search_text,
    });
  }

  render() {
    const {
      handlePagination,
      headers,
      totalPageCount,
      actions,
      rowPress,
      bordered,
      filters,
      showAdd,
      addButtonText,
      handleAddPressed,
      isLoading,
      page,
      placeholder,
      handleSort,
      sortOrder,
      sortColumn,
      tab,
      collapsing,
    } = this.props;

    const { limit, data } = this.state;

    const currentData = data && data.data ? data.data : data; // temporary fix
    const firstPage = 1;
    const lastPage = totalPageCount;

    const paginate = async (numPage) => {
      await handlePagination(numPage, tab);
    };

    const nextPage = async () => {
      if (page === lastPage) return;
      await handlePagination(page + 1, tab);
    };

    const prevPage = async () => {
      if (page === firstPage) return;
      await handlePagination(page - 1, tab);
    };

    return (
      <div>
        <div>
          <div className="table-header-container">
            <div className="table-search-container">
              {this.props.titleContent && <div>{this.props.titleContent}</div>}
              {this.props.isSearch && (
                <>
                  <Input
                    placeholder={placeholder ? placeholder : "Search..."}
                    leftIcon="search"
                    value={
                      this.props.search_text
                        ? this.props.search_text
                        : this.state.search_text
                    }
                    onChange={
                      this.props.handleSearch
                        ? tab
                          ? (e) => this.props.handleSearch(tab, e)
                          : this.props.handleSearch
                        : this.handleSearch.bind(this)
                    }
                    iconRight="search"
                    inputContainerStyle={{
                      marginBottom: 0,
                    }}
                    autoComplete="off"
                    type="search"
                    className="sm w-sm-100"
                    // textarea={"multipleLineText"}
                    // rightButtonIcon={icons.search}
                    // rightButtonPressed={this.props.handleSearchButton}
                  />
                  {this.props.addSearchButton && (
                    <Button
                      className="btn-primary btn-sm"
                      icon="search"
                      onPress={this.props.handlePressSearch}
                      isSubmitting={this.props.searching}
                    />
                  )}
                </>
              )}
            </div>
            {/* <div className="w-md-100"></div> */}
            {filters &&
              filters.map((filter, f) => {
                if (filter.button_type === "dropdown") {
                  return (
                    <>
                      {filter.filterName === "donorCategory" ? (
                        <DonorCategoryFilter
                          handleApplyFilters={filter.handleApplyFilters}
                        />
                      ) : filter.filterName === "center" ? (
                        <CentersFilter
                          handleApplyFilters={filter.handleApplyFilters}
                        />
                      ) : filter.filterName === "centerSite" ? (
                        <CenterSitesFilter
                          handleApplyFilters={filter.handleApplyFilters}
                        />
                      ) : filter.filterName === "hospital" ? (
                        <HospitalFilter
                          handleApplyFilters={filter.handleApplyFilters}
                        />
                      ) : filter.filterName === "bloodGroup" ? (
                        <BloodGroupFilter
                          handleApplyFilters={filter.handleApplyFilters}
                        />
                      ) : filter.filterName === "institution" ? (
                        <InstitutionFilter />
                      ) : filter.filterName === "department" ? (
                        <DepartmentFilter />
                      ) : filter.filterName === "hospital" ? (
                        <HospitalsFilter />
                      ) : filter.filterName === "date_range" ? (
                        <DateFilter
                          handleApplyFilters={filter.handleApplyFilters}
                          startDate={filter.startDate}
                          endDate={filter.endDate}
                        />
                      ) : (
                        <DropdownButton
                          className="btn-transparent btn-bordered w-sm-100 btn-sm"
                          text={filter.title}
                          selected={filter.selected}
                          isSearchable={filter.isSearchable}
                          options={filter.options}
                          onSelectDropdownItem={filter.onSelectDropdownItem}
                          search_text={filter.search_text}
                          onChangeSearch={filter.onChangeSearch}
                          selected_item={filter.selected_item}
                          isLoading={filter.isLoading}
                          isMulti={filter.isMulti}
                          default_key={filter.default_key}
                          handleReset={filter.handleReset}
                          clickBehaviorId="dropdownMenuClickableInside"
                          autoCloseType="outside"
                          icon={filter.icon}
                        >
                          {filter?.options?.map((option, o) => {
                            return (
                              <li key={o}>
                                {option.isDownloadCsv ? (
                                  <CSVLink
                                    filename={option.filename + ".csv"}
                                    data={
                                      option && option.csvData
                                        ? option.csvData
                                        : []
                                    }
                                    asyncOnClick={true}
                                    // onClick={
                                    //   option.onPress ? option.onPress : null
                                    // }
                                    onClick={(event, done) => {
                                      option.onPress().then((res) => {
                                        done(false); // Don't Proceed
                                      });
                                    }}
                                    className="dropdown-item"
                                  >
                                    {option.name
                                      ? option.name
                                      : option.label
                                      ? option.label
                                      : ""}
                                  </CSVLink>
                                ) : (
                                  <Link
                                    onClick={() => option.onPress(option)}
                                    className="dropdown-item"
                                    to="#"
                                  >
                                    {option.name
                                      ? option.name
                                      : option.label
                                      ? option.label
                                      : ""}
                                  </Link>
                                )}
                              </li>
                            );
                          })}
                        </DropdownButton>
                      )}
                    </>
                  );
                } else {
                  return (
                    <Button
                      key={f}
                      selected={filter.selected}
                      className={`btn-transparent btn-bordered w-sm-100 btn-sm`}
                      text={filter.title}
                      onPress={filter.onPress}
                      icon={filter.icon ? filter.icon : "filter_alt"}
                    />
                  );
                }
              })}
            {showAdd && (
              <Button
                className="btn-primary btn-sm"
                onPress={handleAddPressed}
                text={addButtonText}
                icon="add"
              />
            )}
          </div>
        </div>
        {this.props.counts && (
          <div style={{ margin: "1.5rem 0" }}>{this.props.counts}</div>
        )}
        <div className="table-responsive">
          <table
            className={`table table-hover table-condensed ${
              bordered && "table-bordered"
            }`}
            style={this.props.style}
          >
            <thead style={this.props.style_header}>
              <tr>
                {headers &&
                  headers.map((item, i) => {
                    return (
                      <th key={i}>
                        {item.title}
                        {item.sort && (
                          <button
                            className="sort-btn"
                            onClick={() => handleSort(item.key, tab)}
                          >
                            {(sortOrder === -1 || sortOrder === "desc") &&
                            sortColumn === item.key ? (
                              <i className="arrow_upward"></i>
                            ) : (
                              <i className="south"></i>
                            )}
                          </button>
                        )}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className={this.props.tableBodyClass}>
              {isLoading ? (
                <tr>
                  <td align="center" colSpan={headers.length}>
                    <Loading className="primary" />
                  </td>
                </tr>
              ) : !currentData ? (
                <tr>
                  <td colSpan={13} align="center">
                    No data found
                  </td>
                </tr>
              ) : currentData.length === 0 ? (
                <tr>
                  <td colSpan={13} align="center">
                    No data found
                  </td>
                </tr>
              ) : (
                <TableData
                  data={currentData}
                  actions={actions}
                  rowPress={rowPress}
                  headers={headers}
                  collapsing={collapsing}
                >
                  {/* <div style={{ position: "absolute" }}>{collapsingData}</div> */}
                </TableData>
              )}
            </tbody>
          </table>
        </div>
        {totalPageCount !== 0 && totalPageCount && (
          <Pagination
            totalPageCount={totalPageCount}
            limit={limit}
            paginate={paginate}
            length={currentData.length}
            nextPage={nextPage}
            prevPage={prevPage}
            firstPage={firstPage}
            lastPage={lastPage}
            page={page}
            tab={tab}
          />
        )}
      </div>
    );
  }
}

export default Table;
