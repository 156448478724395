import React from "react";
import { connect } from "react-redux";
import language from "../../../language";
import donorsQuery, {
  countDonorsPerCategories,
} from "../../../utils/queries/donorsQuery";
import { getStorage } from "../../../utils/storage";
import { defineRoleCenterSite } from "../../../utils/handleAccessRoles";
import CardCount from "../../CardCount/CardCount";
import handleFilters from "../../../utils/handleFilters";

class CountDonors extends React.Component {
  state = {
    isLoading: false,
    counts: {
      newDonor: 0,
      repeatDonor: 0,
      totalDonors: 0,
    },
  };

  componentDidMount = async () => {
    const user = await getStorage();
    await this.setState({ user });

    await this.fetchCounts();
  };

  fetchCounts() {
    this.getCounts(true, "totalDonors", {});
    this.getCounts(true, "newDonor", { category: "newDonor" });
    this.getCounts(true, "repeatDonor", { category: "repeatDonor" });
    this.getCounts(true, "noDocument", { identityDocNumberType: "noDocument" });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.fetchCounts();
    }
  }

  onChangeText(field, e) {
    let { error } = this.state;

    delete error[field];

    this.setState({
      [field]: e?.target?.value,
      error,
    });
  }

  getCounts = async (isLoadingCounts, type, countFilters) => {
    try {
      const { page, limit, counts, componentFilters } = this.state;
      let { defaultLanguage, centerId, siteId, filters } = this.props;

      this.setState({ isLoadingCounts });

      const { centerRole, centerSiteRole } = await defineRoleCenterSite();

      const { total } = await countDonorsPerCategories(defaultLanguage, {
        page,
        limit,
        center: centerId || centerRole,
        centerSite: siteId || centerSiteRole,
        ...filters,
        ...countFilters,
        ...handleFilters(filters),
      });

      counts[type] = total;

      this.setState({
        counts,
        isLoadingCounts: false,
      });
    } catch (error) {
      console.log(error);
      this.setState({
        isLoadingCounts: false,
      });
    }
  };

  handlePagination(page) {
    this.setState(
      {
        page,
      },
      () => {
        this.getData(true);
      }
    );
  }

  render() {
    console.log(this.props.filters);
    return (
      <div className="row mb-3">
        <div className="col-md-3">
          <CardCount
            count={this.state.counts.totalDonors}
            title={language[this.props.defaultLanguage].total_donors}
            color="orange"
            icon="groups"
            isLoading={this.state.isLoadingCounts}
            // onPress={() => (window.location.href = "/dashboard/donors")}
          />
        </div>
        <div className="col-md-3">
          <CardCount
            count={this.state.counts.newDonor}
            title={language[this.props.defaultLanguage].new_donors}
            color="#078ECE"
            icon="group"
            isLoading={this.state.isLoadingCounts}
            // onPress={() => (window.location.href = "/dashboard/newDonor")}
          />
        </div>
        <div className="col-md-3">
          <CardCount
            count={this.state.counts.repeatDonor}
            title={language[this.props.defaultLanguage].repeat_donors}
            color="gray"
            icon="undo"
            isLoading={this.state.isLoadingCounts}
            // onPress={() => (window.location.href = "/dashboard/repeat_donors")}
          />
        </div>
        <div className="col-md-3">
          <CardCount
            count={this.state.counts.noDocument}
            title={language[this.props.defaultLanguage].donors_with_no_document}
            color="#800080"
            icon="label_off"
            isLoading={this.state.isLoadingCounts}
            // onPress={() => (window.location.href = "/dashboard/noDocument")}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(CountDonors);
