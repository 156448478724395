import React from "react";
import { connect } from "react-redux";
import { Input, Select } from "../Input";
import { Button } from "../Button";
import language from "../../language";
import axios from "axios";
import toastMessage from "../../utils/toastMessage";
import bloodCenterQuery from "../../utils/queries/bloodCenterQuery";
import bloodCenterSiteQuery from "../../utils/queries/bloodCenterSiteQuery";
import { fetchVitalSignsQuery } from "../../utils/queries/vitalSignQuery";
import donorsQuery from "../../utils/queries/donorsQuery";
import accountQuery from "../../utils/queries/accountQuery";
import { getStorage } from "../../utils/storage";
import bloodGroups from "../../constants/bloodGroups";
import moment from "moment";
import CheckboxQuestionnaire from "../Input/CheckboxQuestionnaire";
import { fetchEquipments } from "../../utils/queries/equipmentQuery";
import reactionCategories from "../../constants/reactionCategories";
import { fetchCollectionTypes } from "../../utils/queries/collectionType";
import { fetchPreAssessmentInformation } from "../../utils/queries/preAssessmentQuery";

const API_URL = process.env.REACT_APP_BBMS_BASE_API;

class NewCollection extends React.Component {
  state = {
    username: "",
    error: {},
    isSubmitting: false,
    centers: [],
    sites: [],
    showAccessRoles: false,
    password: "",
    expirationDate: "",
    accounts: [],
    volume: "450",
    equipments: [],
    donors: [],
    collectionTypes: [],
    reactionComment: "",
    donorReaction: false,
    collectionDate: null,
    bloodGroup: bloodGroups[0],
    donationNumber: "",
    accounts: [],
  };

  componentDidMount = async () => {
    const user = await getStorage();

    // if (user.role === "superAdmin") {

    // } else {
    //   this.setState({
    //     collectedBy: {
    //       label: user.displayName || user.firstName + " " + user.lastName,
    //       value: user.id,
    //     },
    //   });
    // }

    await this.getDonors(true);
    await this.getCollectionTypes(true);
    await this.getEquipment(true);

    if (this.props._id) {
      this.setState({
        ...this.props,
        donor: this.props.donor
          ? {
              label: this.props.donor.donorNumber,
              value: this.props.donor._id,
            }
          : undefined,
        donorReaction: this.props.reaction
          ? this.props.reaction.isReaction
          : false,
        vitalSign: this.props.vitalSign
          ? {
              label: moment(this.props.vitalSign.createdAt).format("lll"),
              value: this.props.vitalSign._id,
            }
          : undefined,
        collectionType: this.props.collectionType
          ? {
              label: this.props.collectionType.name,
              value: this.props.collectionType._id,
            }
          : undefined,
        donorReaction: this.props?.reaction
          ? this.props.reaction.isReaction
          : false,
        reactionCategory: this.props?.reaction
          ? {
              label: this.props.reaction.category,
              value: this.props.reaction.category,
            }
          : undefined,
        reactionComment: this.props?.reaction
          ? this.props?.reaction.comment
          : "",
        phlebotomist: this.props.phlebotomist
          ? {
              label: this.props.phlebotomist.name,
              value: this.props.phlebotomist._id,
            }
          : undefined,
        center: this.props?.center?._id
          ? {
              label: this.props.center.name,
              value: this.props.center._id,
            }
          : undefined,
        collectionType: this.props?.collectionType
          ? {
              label: this.props.collectionType.name,
              value: this.props.collectionType._id,
            }
          : undefined,
        site: this.props?.centerSite?._id
          ? {
              label: this.props.centerSite.name,
              value: this.props.centerSite._id,
            }
          : undefined,
        bloodGroup: this.props.bloodInfo.bloodGroup
          ? {
              label: this.props.bloodInfo.bloodGroup,
              value: this.props.bloodInfo.bloodGroup,
            }
          : null,
        equipment: this.props.equipment
          ? {
              label: this.props.equipment.name,
              value: this.props.equipment._id,
            }
          : null,
        volume: this.props?.bloodInfo?.volume || 450,
        startTime: this.props.startTime,
        endTime: this.props.endTime,
        collectionDate: moment(this.props.collectionDate).format("YYYY-MM-DD"),
      });
    }
  };

  getCenters = async (isFetchingCenter) => {
    try {
      this.setState({
        isFetchingCenter,
      });

      const data = await bloodCenterQuery(this.props.defaultLanguage, {
        type: "dropdown",
        id: this.state.vitalSign?.preDonationAssessment?.center?._id,
      });

      this.setSelectedCenter(data);

      this.setState({
        centers: data,
        isFetchingCenter: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingCenter: false });

      return [];
    }
  };

  getCollectionTypes = async (isFetchingCollectionType) => {
    try {
      this.setState({
        isFetchingCollectionType,
      });

      const data = await fetchCollectionTypes(this.props.defaultLanguage, {
        type: "dropdown",
      });

      this.setState({
        collectionTypes: data,
        isFetchingCollectionType: false,
      });
    } catch (error) {
      this.setState({ isFetchingCollectionType: false });
    }
  };

  getVitalSigns = async (search = {}) => {
    try {
      this.setState({
        isFetchingSigns: true,
      });

      const data = await fetchVitalSignsQuery(this.props.defaultLanguage, {
        type: "dropdown",
        donor: this.props.donorId,
        page: 1,
        limit: 50,
        status: "pending",
        ...search,
      });

      this.setSelectedSign(data);

      let { vitalSign } = this.state;

      if (!this.props._id && data.length === 1) {
        vitalSign = {
          ...data[0],
          label: moment(data[0].createdAt).format("lll"),
          value: data[0]._id,
        };

        this.setState({
          collectionDate: moment(
            vitalSign?.preDonationAssessment?.requestedDate
          ).format("YYYY-MM-DD"),
          startTime: moment(
            vitalSign?.preDonationAssessment?.requestedDate
          ).format("HH:mm"),
        });
      }

      this.setState({
        vitalSigns: data,
        isFetchingSigns: false,
        vitalSign,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingSigns: false });

      return [];
    }
  };

  getEquipment = async (isFetchingEquipment) => {
    try {
      this.setState({
        isFetchingEquipment,
      });

      const { center, centerSite } = this.state;

      const data = await fetchEquipments(this.props.defaultLanguage, {
        type: "dropdown",
        department: "collection",
        center: center?.value || undefined,
        // centerSite: centerSite?.value || undefined,
      });

      this.setSelectedEquipment(data);

      this.setState({
        equipments: data,
        isFetchingEquipment: false,
      });
    } catch (error) {
      this.setState({ isFetchingEquipment: false });
    }
  };

  getSites = async (isFetchingSite, center) => {
    try {
      this.setState({
        isFetchingSite,
      });

      const data = await bloodCenterSiteQuery(this.props.defaultLanguage, {
        center,
        type: "dropdown",
      });

      this.setSelectedSite(data, this.props.position);

      this.setState({
        sites: data,
        isFetchingSite: false,
      });
    } catch (error) {
      this.setState({ isFetchingSite: false });
    }
  };

  getDonors = async (isFetchingDonor) => {
    try {
      let { center = {}, site = {} } = this.state;

      this.setState({
        isFetchingDonor,
      });

      const data = await donorsQuery(this.props.defaultLanguage, {
        type: "dropdown",
        center: center.value,
        centerSite: site.value,
        id: this.props.donorId,
      });

      this.setSelectedDonor(data);

      let { bloodGroup, donor } = this.state;

      if (data.length > 0 && !this.props._id) {
        donor = {
          label: data[0].firstName + " " + data[0].lastName,
          value: data[0]._id,
          ...data[0],
        };

        if (data[0].bloodGroup && data[0].bloodGroup !== "") {
          bloodGroup = {
            label: data[0].bloodGroup,
            value: data[0].bloodGroup,
          };
        }

        if (data[0].center) {
          center = {
            label: data[0].center.name,
            value: data[0].center._id,
          };
        }

        if (data[0].centerSite) {
          site = {
            label: data[0].centerSite.name,
            value: data[0].centerSite._id,
          };
        }
      }

      this.setState({
        donors: data,
        isFetchingDonor: false,
        bloodGroup,
        donor,
        center,
        site,
      });
    } catch (error) {
      console.log(error);
      this.setState({ isFetchingDonor: false });
    }
  };

  getAccounts = async (q) => {
    try {
      this.setState({
        isFetchingAccount: true,
      });

      const data = await accountQuery(this.props.defaultLanguage, {
        type: "dropdown",
        q: q ? q : undefined,
        page: 1,
        limit: 50,
        center: this.state.vitalSign?.preDonationAssessment?.center?._id,
      });

      this.setState({
        accounts: data,
        isFetchingAccount: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingAccount: false });

      return [];
    }
  };

  setSelectedCenter(centers) {
    const { center } = this.props;
    if (center) {
      const selectedCenter = centers.find((el) => el.value === center._id);

      if (selectedCenter?._id) {
        this.setState(
          {
            center: {
              label: selectedCenter.name,
              value: selectedCenter._id,
            },
          },
          () => {
            this.getSites(true, selectedCenter._id);
          }
        );
      }
    }
  }

  setSelectedEquipment(equipments) {
    const { equipment } = this.props;
    if (equipment) {
      const selectedData = equipments.find((el) => el.value === equipment._id);

      if (selectedData?._id) {
        this.setState({
          equipment: {
            label: selectedData.name,
            value: selectedData._id,
          },
        });
      }
    }
  }

  setSelectedAccount(centers) {
    const { phlebotomist } = this.props;
    if (phlebotomist) {
      const selectedAccount = centers.find((el) => el.label === phlebotomist);

      if (selectedAccount?._id) {
        this.setState({
          phlebotomist: {
            label: selectedAccount.username,
            value: selectedAccount._id,
          },
        });
      }
    }
  }

  setSelectedSite(sites) {
    const { centerSite } = this.props;
    if (centerSite) {
      const selectedSite = sites.find((el) => el.value === centerSite._id);

      if (selectedSite?._id) {
        this.setState({
          site: {
            label: selectedSite.name,
            value: selectedSite._id,
          },
        });
      }
    }
  }

  setSelectedDonor(donors) {
    const { donor } = this.props;
    if (donor) {
      const selectedDonor = donors.find((el) => el._id === donor._id);

      if (selectedDonor._id) {
        this.setState({
          donor: {
            label: selectedDonor.donorNumber,
            value: selectedDonor._id,
          },
        });
      }
    }
  }

  setSelectedSign(signs) {
    const { vitalSign } = this.props;
    if (vitalSign) {
      const selectedSign = signs.find((el) => el.value === vitalSign._id);

      if (selectedSign?._id) {
        this.setState({
          vitalSign: {
            label:
              selectedSign?.donor?.donorNumber +
              " - " +
              moment(selectedSign.createdAt).format("lll"),
            value: selectedSign._id,
          },
        });
      }
    }
  }

  getAppointments = async (donor, appointmentNumber) => {
    try {
      if (!donor) return;
      this.setState({
        isFetchingAppointment: true,
      });

      const data = await fetchPreAssessmentInformation(
        this.props.defaultLanguage,
        {
          type: "dropdown",
          donor,
          status: "approved",
          page: 1,
          limit: 50,
          appointmentNumber,
          isEligible: true,
        }
      );

      console.log(data);

      this.setState({
        appointments: data,
        isFetchingAppointment: false,
      });

      return data;
    } catch (error) {
      this.setState({ isFetchingAppointment: false });

      return [];
    }
  };

  handleCheck = async (field, value) => {
    let { error } = this.state;

    delete error[field];

    this.setState({ [field]: value, error });

    if (field === "donorReaction" && !value) {
      this.setState({
        reactionCategory: null,
        reactionComment: "",
      });
    }
  };

  onChangeText = async (field, e) => {
    let { error } = this.state;
    let inputValue = e.target ? e?.target?.value : e;
    delete error[field];

    await this.setState({
      [field]: inputValue,
      error,
    });

    if (field === "center") {
      this.setState({ site: null });
      this.getSites(true, inputValue.value);
    }

    if (field === "startTime") {
      this.setState({
        startTime: inputValue.slice(0, 16),
      });
    }

    if (field === "endTime") {
      this.setState({
        endTime: inputValue.slice(0, 16),
      });
    }

    if (field === "preDonationAssessment") {
      this.getAccounts(true);
      this.getCenters(true);
    }

    if (field === "vitalSign") {
      delete error["collectionDate"];
      this.setState({
        collectionDate: moment(
          inputValue?.preDonationAssessment?.requestedDate
        ).format("YYYY-MM-DD"),
        startTime: moment(
          inputValue?.preDonationAssessment?.requestedDate
        ).format("HH:mm"),
        error,
      });
    }
  };

  validateForm() {
    let {
      bloodRecorder,
      volume,
      site,
      donor,
      center,
      error,
      vitalSign,
      collectionType,
      equipment,
      startTime,
      endTime,
      donationNumber,
      collectionDate,
    } = this.state;

    if (donationNumber === "") {
      error.donationNumber =
        language[this.props.defaultLanguage].donation_number_required;
    }

    if (volume === "") {
      error.volume = language[this.props.defaultLanguage].volume_required;
    }

    if (!vitalSign || vitalSign === "") {
      error.vitalSign =
        language[this.props.defaultLanguage].vital_sign_required;
    }

    if (!center) {
      error.center = language[this.props.defaultLanguage].center_required;
    }

    if (!donor && !this.props.donorId) {
      error.donor = language[this.props.defaultLanguage].donor_required;
    }

    if (!site) {
      error.site = language[this.props.defaultLanguage].site_required;
    }

    if (!bloodRecorder) {
      error.bloodRecorder =
        language[this.props.defaultLanguage].blood_recorder_required;
    }

    if (!vitalSign) {
      error.vitalSign =
        language[this.props.defaultLanguage].vital_sign_required;
    }

    if (!collectionType) {
      error.collectionType =
        language[this.props.defaultLanguage].collection_type_required;
    }

    if (!equipment) {
      error.equipment = language[this.props.defaultLanguage].equipment_required;
    }

    if (!startTime || startTime === "") {
      error.startTime =
        language[this.props.defaultLanguage].start_time_required;
    } else if (startTime === "Invalid date") {
      error.startTime =
        language[this.props.defaultLanguage].start_time_required;
    }

    if (!endTime || endTime === "") {
      error.endTime = language[this.props.defaultLanguage].end_time_required;
    } else if (endTime === "Invalid date") {
      error.endTime = language[this.props.defaultLanguage].end_time_required;
    }

    if (!collectionDate || collectionDate === "") {
      error.collectionDate = language[this.props.defaultLanguage].date_required;
    }

    this.setState({ error });
  }

  onSubmit = async () => {
    await this.validateForm();

    console.log({
      startTime: this.state.startTime,
      endTime: this.state.endTime,
    });

    if (Object.keys(this.state.error).length === 0) {
      this.setState({
        isSubmitting: true,
      });

      let { _id } = this.props;
      let {
          phlebotomist,
          volume,
          site,
          donor,
          center,
          bloodGroup,
          startTime,
          endTime,
          donorReaction,
          equipment,
          vitalSign,
          reactionCategory,
          collectionType,
          reactionComment,
          donationNumber,
          collectionDate,
          bloodRecorder,
        } = this.state,
        url = `${API_URL}/blooddonation`,
        method = "POST",
        user = await getStorage();

      let requestedBody = {
        donor: donor.value || this.props.donorId,
        bloodInfo: {
          bloodGroup: bloodGroup?.value || bloodGroup,
          volume,
        },
        center: center.value,
        centerSite: site.value,
        phlebotomist: phlebotomist?.value,
        startTime,
        endTime,
        vitalSign: vitalSign.value,
        equipment: equipment?.value,
        status: "pending",
        collectionType: collectionType?.value || "",
        reaction: {
          isReaction: donorReaction,
          category: reactionCategory?.value || "",
          comment: reactionComment || "",
        },
        donationNumber,
        collectionDate,
        bloodRecorder: bloodRecorder?.value,
      };

      if (_id && _id !== "") {
        method = "PUT";
        requestedBody.id = _id;
        requestedBody.modifiedBy = user.id;
      } else {
        requestedBody.addedBy = user.id;
      }

      const options = {
        method,
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
        data: requestedBody,
      };
      axios(options)
        .then((data) => {
          this.setState({
            isSubmitting: false,
          });

          toastMessage(
            "success",
            language[this.props.defaultLanguage][
              method === "PUT"
                ? "update_blooddonation_success"
                : "add_blooddonation_success"
            ]
          );

          this.props.getData(true);
          this.props.handleCloseModal();
        })
        .catch((error) => {
          this.setState({ isSubmitting: false });
          toastMessage("error", error);
        });
    }
  };

  render() {
    return (
      <>
        <div>
          <div className="card-body">
            <div className="row">
              <>
                <div className="col-md-12">
                  <Input
                    placeholder={
                      language[this.props.defaultLanguage].enter_donation_number
                    }
                    label={language[this.props.defaultLanguage].donation_number}
                    required
                    value={this.state.donationNumber}
                    onChange={(e) => this.onChangeText("donationNumber", e)}
                    error={this.state.error.donationNumber}
                  />
                </div>
                <div className="col-md-12">
                  <Select
                    options={this.state.donors}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].donor_number}
                    required
                    value={this.state.donor}
                    onChange={(e) => this.onChangeText("donor", e)}
                    error={this.state.error.donor}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    options={this.state.centers}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].center}
                    required
                    value={this.state.center}
                    onChange={(e) => this.onChangeText("center", e)}
                    error={this.state.error.center}
                    isLoading={this.state.isFetchingCenter}
                  />
                </div>
                <div className="col-md-6">
                  <Select
                    options={this.state.sites}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].site}
                    required
                    value={this.state.site}
                    onChange={(e) => this.onChangeText("site", e)}
                    error={this.state.error.site}
                    isLoading={this.state.isFetchingSite}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    loadOptions={(e) => this.getAccounts(e)}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].blood_recorder}
                    // required
                    value={this.state.bloodRecorder}
                    onChange={(e) => this.onChangeText("bloodRecorder", e)}
                    error={this.state.error.bloodRecorder}
                    isLoading={this.state.isFetchingAccount}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    loadOptions={(e) =>
                      this.getVitalSigns({ vitalSignNumber: e })
                    }
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].vital_sign}
                    required
                    value={this.state.vitalSign}
                    onChange={(e) => this.onChangeText("vitalSign", e)}
                    error={this.state.error.vitalSign}
                    isLoading={this.state.isFetchingSigns}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    options={this.state.equipments}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={
                      language[this.props.defaultLanguage].used_equipment_id
                    }
                    required
                    value={this.state.equipment}
                    onChange={(e) => this.onChangeText("equipment", e)}
                    error={this.state.error.equipment}
                    isLoading={this.state.isFetchingEquipment}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    options={this.state.collectionTypes}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={language[this.props.defaultLanguage].collection_type}
                    required
                    value={this.state.collectionType}
                    onChange={(e) => this.onChangeText("collectionType", e)}
                    error={this.state.error.collectionType}
                    isLoading={this.state.isFetchingCollectionType}
                  />
                </div>
              </>
              <div className="col-md-6">
                <Select
                  loadOptions={(e) => this.getAccounts(e)}
                  placeholder={language[this.props.defaultLanguage].select}
                  label={language[this.props.defaultLanguage].phlebotomist}
                  // required
                  value={this.state.phlebotomist}
                  onChange={(e) => this.onChangeText("phlebotomist", e)}
                  error={this.state.error.phlebotomist}
                  isLoading={this.state.isFetchingAccount}
                />
              </div>

              <div className="col-md-12">
                <Input
                  label={language[this.props.defaultLanguage].collection_date}
                  value={this.state.collectionDate}
                  onChange={(e) => this.onChangeText("collectionDate", e)}
                  error={this.state.error.collectionDate}
                  type="date"
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={language[this.props.defaultLanguage].start_time}
                  value={this.state.startTime}
                  onChange={(e) => this.onChangeText("startTime", e)}
                  error={this.state.error.startTime}
                  type="time"
                  // min={moment().format("hh:mm")}
                />
              </div>
              <div className="col-md-6">
                <Input
                  label={language[this.props.defaultLanguage].end_time}
                  value={this.state.endTime}
                  onChange={(e) => this.onChangeText("endTime", e)}
                  error={this.state.error.endTime}
                  type="time"
                  // min={moment().format("hh:mm")}
                />
              </div>
              <div className="col-md-12">
                <Input
                  placeholder={
                    language[this.props.defaultLanguage].volume_placeholder
                  }
                  label={language[this.props.defaultLanguage].volume}
                  // required
                  value={this.state.volume}
                  onChange={(e) => this.onChangeText("volume", e)}
                  error={this.state.error.volume}
                  type="number"
                />
              </div>
              <CheckboxQuestionnaire
                questionText={
                  language[this.props.defaultLanguage].any_donor_reaction
                }
                answers={[
                  {
                    field: "donorReaction",
                    value: language[this.props.defaultLanguage].yes,
                    onPress: () => this.handleCheck("donorReaction", true),
                  },
                  {
                    field: "donorReaction",
                    value: language[this.props.defaultLanguage].no,
                    onPress: () => this.handleCheck("donorReaction", false),
                  },
                ]}
                checked={this.state.donorReaction === true ? "Yes" : "No"}
                error={this.state.error.donorReaction}
              />
            </div>
            {this.state.donorReaction === true && (
              <div className="row">
                <div className="col-md-12">
                  <Select
                    options={reactionCategories(this.props.defaultLanguage)}
                    placeholder={language[this.props.defaultLanguage].select}
                    label={
                      language[this.props.defaultLanguage].reaction_category
                    }
                    required
                    value={this.state.reactionCategory}
                    onChange={(e) => this.onChangeText("reactionCategory", e)}
                    error={this.state.error.reactionCategory}
                  />
                </div>
                <div className="col-md-12">
                  <Input
                    label={language[this.props.defaultLanguage].comment}
                    placeholder={
                      language[this.props.defaultLanguage].comment_placeholder
                    }
                    required
                    value={this.state.reactionComment}
                    onChange={(e) => this.onChangeText("reactionComment", e)}
                    error={this.state.error.reactionComment}
                    textarea
                  />
                </div>
              </div>
            )}
          </div>
          <div className="modal-footer">
            <Button
              text={language[this.props.defaultLanguage].cancel}
              onPress={this.props.handleCloseModal}
              className="btn-default"
            />
            <Button
              text={language[this.props.defaultLanguage].submit}
              onPress={this.onSubmit.bind(this)}
              isSubmitting={this.state.isSubmitting}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(NewCollection);
